import type { ApolloLink } from '@apollo/client'
import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'
import type { ApolloPersistOptions } from 'apollo3-cache-persist/lib/types'
import localForage from 'localforage'

import authContext from './authContext'
import cache from './cache'
import { errorLink, httpLink, persistedQueriesLink, retryLink } from './links'

const isProduction = import.meta.env?.MODE === 'production'

const link = from(
	[
		isProduction && retryLink,
		authContext,
		errorLink,
		isProduction && persistedQueriesLink,
		httpLink,
	].filter(Boolean) as ApolloLink[],
)

const storage = localForage

const MEGABYTE = 1048576
const PERSIST_MAX_MB = 50

const persistOptions = Object.freeze({
	cache,
	storage: new LocalForageWrapper(storage),
	maxSize: MEGABYTE * PERSIST_MAX_MB,
} as unknown as ApolloPersistOptions<any, any>)

const cachePersistor = new CachePersistor(persistOptions)

const defaultQueryOptions = {
	returnPartialData: true,
	fetchPolicy: 'cache-and-network',
	//  The default value is false for backwards-compatibility's sake, but should be changed to true for most use-cases.
	partialRefetch: true,
	errorPolicy: 'all', // this will return data and errors
} as const

const apolloClient = new ApolloClient({
	cache,
	link,
	name: '@cretia/web',
	version: __APP_VERSION__,
	defaultOptions: {
		watchQuery: defaultQueryOptions,
	},
})

export const publicClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: from([httpLink] as unknown as ApolloLink[]),
	defaultOptions: { watchQuery: defaultQueryOptions },
})

export {
	cache,
	storage,
	cachePersistor,
	persistOptions,
	apolloClient as client,
}

export default apolloClient
