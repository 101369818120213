import { Button, TextInputContainer } from '@cretia/components'
import { Link } from 'react-router-dom'
import { css } from 'styled-components'
import styled from 'styled-components'

import { Container as SpinnerContainer } from '@/components/Spinner/styled'

import googleIcon from './assets/google-icon.svg'

export const Container = styled.div`
	min-height: 100vh;
	min-height: 100dvh;
	max-height: -webkit-fill-available;
	display: grid;
	grid-template-rows: 50px 1fr 50px;
	overflow: auto;
`

export const Main = styled.main`
	margin: 10vh auto 0;
	max-width: 400px;
	padding: 0 16px;
	width: 100%;

	> p {
		font-size: 1rem;
		color: var(--human-color--text);
		margin-bottom: 16px;
	}
`

export const SubmitWrapper = styled.div<{ margin?: boolean }>`
	position: relative;
	margin: ${({ margin }) => margin && '8px 0'};

	${SpinnerContainer} {
		position: absolute;
		top: auto;
		bottom: 0;
		right: 0;
		display: inline-block;
		width: 26px;
		left: auto;
		margin: 8.5px 9.5px;
		height: 26px;
	}

	button {
		position: absolute;
		bottom: 0;
		right: 0;
		color: var(--human-color--gray);
		width: 41px;
		height: 41px;
		padding: 0;
		border-radius: 50%;
		z-index: 3;
		background-size: cover;
		font-size: 0;
		margin-top: -10px;

		&:focus,
		&:hover {
			box-shadow: 0 0 0 2px var(--human-color--blue);
		}
		&:active {
			opacity: 0.8;
		}
	}
`

export const ProviderLogin = styled.div`
	border: 1px solid var(--human-color--gray-4);
	margin: 8px 0 4px;
	border-radius: var(--border_radius--medium);
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	height: 38px;
	overflow: hidden;

	p {
		margin-bottom: 8px;
	}

	> * {
		flex-basis: 50%;
		border-right: 1px solid var(--human-color--gray-4);
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			width: 100%;
			span {
				display: none;
			}

			[role='button'] {
				border: none;
			}

			div {
				margin-right: 0 !important;
				justify-content: center;
			}
		}

		&[disabled] {
			opacity: 0.5;
		}

		&:hover:not([disabled]) {
			text-decoration: none;
			background-color: var(--human-color--gray-6);
		}
		&:last-child {
			border-right: none;
		}
	}
`

export const SubmitButton = styled(Button)`
	width: 100%;
	margin-top: var(--space--x_large);
`

export const Form = styled.form<{ isSingleInput?: boolean }>`
	${TextInputContainer} input {
		padding: 11px 12px;

		&:focus {
			z-index: 1;
		}
	}

	${({ isSingleInput }) =>
		!isSingleInput &&
		css`
			*:first-child ${TextInputContainer} input {
				border-radius: 8px 8px 0 0;
			}

			*:nth-child(2) ${TextInputContainer} input {
				margin-top: -1px;
				border-radius: 0 0 8px 8px;
				padding-right: 36px;
			}
		`}
`

export const AlternativeLoginLabel = styled.span`
	color: var(--human-color--text-secondary);
	font-size: 0.75rem;
	font-weight: 600;
	text-align: center;
	display: block;
	margin: 32px auto 0;
`

export const ForgotLink = styled(Link)<{ $inline?: boolean }>`
	${({ $inline }) =>
		!$inline &&
		`margin-top: 32px;
  display: block;`}

	color: var(--human-color--blue);
	text-align: center;
	font-size: 0.9rem;
`

export const ForgotContainer = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 16;
`

export const AlternativeLoginError = styled.p`
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	padding: var(--space--small);
	color: var(--human-color--red);
	font-weight: 600;
	font-size: 0.9rem;
`

export const HeaderLink = styled(Link)`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: var(--space--medium) 0 var(--space--large);
	gap: var(--space--medium);

	&:hover {
		text-decoration: none;
	}
`

export const LoginButton = styled.button`
	background-size: 25px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;

	&:active {
		background-color: inherit;
	}
`

export const disabledButton = css`
	filter: grayscale(100%);

	&:hover {
		pointer-events: none;
		background-color: inherit;
		cursor: default;
	}
`

export const GoogleLoginButton = styled(LoginButton)<{ disabled?: boolean }>`
	background-image: url(${googleIcon});

	${({ disabled }): any => disabled && disabledButton};
`

export const AppVersion = styled.span`
	color: var(--human-color--text-secondary);
	display: block;
	font-size: 0.9rem;
`

export const TopAboveContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
	user-select: none;
	-webkit-user-select: none;
	-webkit-app-region: drag;

	a {
		margin: 0 5px;
		font-size: 0.9rem;
		color: var(--human-color--text-secondary);
	}
`
export const OrgHeadings = styled.div`
	text-align: center;
	margin-bottom: 24px;

	h1,
	h2 {
		margin-bottom: 8px;
	}
`
