import {
	Content as RadixContent,
	Overlay as RadixOverlay,
	Portal,
	Root,
	Title as RadixTitle,
} from '@radix-ui/react-dialog'
import styled, { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: var(--human-color--overlay);
  }
`

export const Title = styled(RadixTitle)`
	text-align: center;
	font-size: 1.3rem;
	font-weight: 600;
`

export { Root, Portal }

export const Hr = styled.hr`
	margin: var(--space--medium) 0;
`

export const overlayStyles = css`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: grid;
	padding: 10px;
	position: fixed;
	overflow-y: auto;
	justify-items: center;
	z-index: var(--z-index--modal);
	background-color: var(--human-color--overlay);
	animation: ${fadeIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const Overlay = styled(RadixOverlay)<{ $top?: boolean }>`
	${overlayStyles}
	align-items: ${({ $top }) => ($top ? 'start' : 'center')};
`

export const Container = styled(RadixContent)<{
	$paddingless: boolean
	$maxWidth: number
	$bottomBorderRadius: boolean
}>`
	padding: ${({ $paddingless }) =>
		$paddingless ? '0' : 'var(--space--medium)'};
	cursor: auto;
	box-shadow:
		hsl(206 22% 7% / 35%) 0 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0 10px 20px -15px;
	width: 100%;
	max-width: ${({ $maxWidth }) => $maxWidth}px;
	border-radius: ${({ $bottomBorderRadius }) =>
		$bottomBorderRadius ? '20px' : '20px 20px 0 0'};
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	z-index: var(--z-index--modal);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
	background: var(--human-color--background);

	h4 {
		font-size: 1.2rem;
		margin-bottom: 16px;
	}

	h4,
	h5 {
		text-align: left;
	}

	h5 {
		font-size: 1.05rem;
		margin-top: 20px;
		margin-bottom: var(--space--small);
	}

	h6 {
		color: var(--color-text);
		font-weight: 500;
		font-size: 0.9rem;
		margin-bottom: var(--space--small);
	}
`
