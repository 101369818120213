// https://en.wikipedia.org/wiki/Category:Lists_of_companies_by_industry
export enum CLIENT_TYPES {
	AIRLINE = 'AIRLINE',
	AIRPORT = 'AIRPORT',
	ALTERNATIVE_MEDICINE = 'ALTERNATIVE_MEDICINE',
	ANIMAL_HOSPITAL = 'ANIMAL_HOSPITAL',
	ANIMATION = 'ANIMATION',
	APPAREL_AND_FASHION = 'APPAREL_AND_FASHION',
	ARCHITECTURE = 'ARCHITECTURE',
	ARTS_AND_CRAFTS = 'ARTS_AND_CRAFTS',
	AUTOMOTIVE = 'AUTOMOTIVE',

	BANKING = 'BANKING',
	BIOTECHNOLOGY = 'BIOTECHNOLOGY',
	BOOK_STORE = 'BOOK_STORE',
	BROADCAST_MEDIA = 'BROADCAST_MEDIA',
	BUILDING_MATERIALS = 'BUILDING_MATERIALS',
	BUSINESS_SUPPLIES_AND_EQUIPMENT = 'BUSINESS_SUPPLIES_AND_EQUIPMENT',

	CAPITAL_MARKETS = 'CAPITAL_MARKETS',
	CHEMICALS = 'CHEMICALS',
	CIVIC_AND_SOCIAL_ORGANIZATION = 'CIVIC_AND_SOCIAL_ORGANIZATION',
	CIVIL_ENGINEERING = 'CIVIL_ENGINEERING',
	COMPUTER_AND_NETWORK_SECURITY = 'COMPUTER_AND_NETWORK_SECURITY',
	COMPUTER_HARDWARE = 'COMPUTER_HARDWARE',
	COMPUTER_NETWORKING = 'COMPUTER_NETWORKING',
	COMPUTER_SOFTWARE = 'COMPUTER_SOFTWARE',
	CONSTRUCTION = 'CONSTRUCTION',
	CONSUMER_ELECTRONICS = 'CONSUMER_ELECTRONICS',
	CONSUMER_GOODS = 'CONSUMER_GOODS',
	CONSUMER_SERVICES = 'CONSUMER_SERVICES',
	COSMETICS = 'COSMETICS',

	DESIGN = 'DESIGN',
	DIGITAL_MEDIA = 'DIGITAL_MEDIA',

	EDUCATION = 'EDUCATION',
	ELECTRONICS = 'ELECTRONICS',
	ENERGY = 'ENERGY',
	ENGINEERING = 'ENGINEERING',
	ENTERTAINMENT = 'ENTERTAINMENT',
	ENVIRONMENTAL_SERVICES = 'ENVIRONMENTAL_SERVICES',
	EVENTS_SERVICES = 'EVENTS_SERVICES',

	FARMING = 'FARMING',
	FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
	FINE_ART = 'FINE_ART',
	FISHERY = 'FISHERY',
	FOOD_AND_DRINK = 'FOOD_AND_DRINK',
	FURNITURE = 'FURNITURE',

	GAMBLING_AND_CASINOS = 'GAMBLING_AND_CASINOS',
	GOVERNMENT = 'GOVERNMENT',
	GRAPHIC_DESIGN = 'GRAPHIC_DESIGN',

	HOME = 'HOME',
	HEALTHCARE = 'HEALTHCARE',
	HEALTH_WELLNESS_AND_FITNESS = 'HEALTH_WELLNESS_AND_FITNESS',
	HIGHER_EDUCATION = 'HIGHER_EDUCATION',
	HOSPITALITY = 'HOSPITALITY',
	HUMAN_RESOURCES = 'HUMAN_RESOURCES',

	IMPORT_AND_EXPORT = 'IMPORT_AND_EXPORT',
	INFORMATION_SERVICES = 'INFORMATION_SERVICES',
	INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
	INSURANCE = 'INSURANCE',
	INTERNATIONAL_AFFAIRS = 'INTERNATIONAL_AFFAIRS',
	INTERNATIONAL_TRADE_AND_DEVELOPMENT = 'INTERNATIONAL_TRADE_AND_DEVELOPMENT',
	INTERNET = 'INTERNET',
	INVESTMENT_BANKING = 'INVESTMENT_BANKING',
	INVESTMENT_MANAGEMENT = 'INVESTMENT_MANAGEMENT',
	JUDICIARY = 'JUDICIARY',
	LAW_ENFORCEMENT = 'LAW_ENFORCEMENT',
	LAW_PRACTICE = 'LAW_PRACTICE',
	LEGAL_SERVICES = 'LEGAL_SERVICES',
	LIBRARIES = 'LIBRARIES',
	LOGISTICS_AND_SUPPLY_CHAIN = 'LOGISTICS_AND_SUPPLY_CHAIN',
	LUXURY_GOODS_AND_JEWELRY = 'LUXURY_GOODS_AND_JEWELRY',
	MACHINERY = 'MACHINERY',
	MANAGEMENT_CONSULTING = 'MANAGEMENT_CONSULTING',
	MARITIME = 'MARITIME',
	MARKETING_AND_ADVERTISING = 'MARKETING_AND_ADVERTISING',
	MARKET_RESEARCH = 'MARKET_RESEARCH',
	MECHANICAL_OR_INDUSTRIAL_ENGINEERING = 'MECHANICAL_OR_INDUSTRIAL_ENGINEERING',
	MEDIA_PRODUCTION = 'MEDIA_PRODUCTION',
	MEDICAL_DEVICE = 'MEDICAL_DEVICE',
	MEDICAL_PRACTICE = 'MEDICAL_PRACTICE',
	MENTAL_HEALTH_CARE = 'MENTAL_HEALTH_CARE',
	MILITARY_AND_DEFENSE = 'MILITARY_AND_DEFENSE',
	MINING_AND_MINERALS = 'MINING_AND_MINERALS',
	MOTION_PICTURES_AND_FILM = 'MOTION_PICTURES_AND_FILM',
	MUSEUMS_AND_INSTITUTIONS = 'MUSEUMS_AND_INSTITUTIONS',
	MUSIC = 'MUSIC',

	NANOTECHNOLOGY = 'NANOTECHNOLOGY',
	NEWSPAPERS = 'NEWSPAPERS',
	NON_PROFIT_ORGANIZATIONS = 'NON_PROFIT_ORGANIZATIONS',

	OIL_AND_GAS = 'OIL_AND_GAS',
	ONLINE_MEDIA = 'ONLINE_MEDIA',
	OUTSOURCING = 'OUTSOURCING',

	PEST_CONTROL = 'PEST_CONTROL',
	PERFORMING_ARTS = 'PERFORMING_ARTS',
	PHILANTHROPY = 'PHILANTHROPY',
	PHOTOGRAPHY = 'PHOTOGRAPHY',
	PLASTICS = 'PLASTICS',
	POLITICAL_ORGANIZATION = 'POLITICAL_ORGANIZATION',
	PRINTING = 'PRINTING',
	PROFESSIONAL_TRAINING = 'PROFESSIONAL_TRAINING',
	PHARMACEUTICAL = 'PHARMACEUTICAL',
	PUBLIC_POLICY = 'PUBLIC_POLICY',
	PUBLIC_RELATIONS = 'PUBLIC_RELATIONS',
	PUBLISHING = 'PUBLISHING',

	REAL_ESTATE = 'REAL_ESTATE',
	RECREATION_AND_LEISURE = 'RECREATION_AND_LEISURE',
	RELIGIOUS_INSTITUTIONS = 'RELIGIOUS_INSTITUTIONS',
	RESEARCH = 'RESEARCH',
	RESTAURANT = 'RESTAURANT',
	RETAIL = 'RETAIL',

	SECURITY = 'SECURITY',
	SEMICONDUCTORS = 'SEMICONDUCTORS',
	SPORTING_GOODS = 'SPORTING_GOODS',
	SOFTWARE = 'SOFTWARE',
	SPORTS = 'SPORTS',
	STAFFING_AND_RECRUITMENT = 'STAFFING_AND_RECRUITMENT',
	SUPERMARKETS = 'SUPERMARKETS',

	TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
	TEXTILES = 'TEXTILES',
	TRANSPORTATION = 'TRANSPORTATION',
	TOURISM = 'TOURISM',

	UTILITIES = 'UTILITIES',

	VETERINARY = 'VETERINARY',

	WAREHOUSES_AND_STORAGE = 'WAREHOUSES_AND_STORAGE',
	WHOLESALE = 'WHOLESALE',
	WINE_AND_SPIRITS = 'WINE_AND_SPIRITS',
	WATER_AND_WASTEWATER_TREATMENT = 'WATER_AND_WASTEWATER_TREATMENT',
	WIRELESS_COMMUNICATION = 'WIRELESS_COMMUNICATION',
	WRITING_AND_EDITING = 'WRITING_AND_EDITING',

	ZOOLOGY = 'ZOOLOGY',
}
