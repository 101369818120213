import { gql } from '@apollo/client'

import UserFragment from '@/lib/graphql/fragments/user'

const getSelf = gql`
	query GetSelf {
		selectedFacility @client
		self {
			...UserFragment
		}
	}
	${UserFragment}
`

export default getSelf
