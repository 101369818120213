import { css, keyframes } from 'styled-components'
import styled from 'styled-components'

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const fadeIn = keyframes`
  79% {
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`

export const Container = styled.div<{
	small?: boolean
	$fullSize?: boolean
	translucent?: boolean
	absolute?: boolean
	marginTop?: string
	$immediate?: boolean
}>`
	${({ $immediate }) =>
		!$immediate &&
		css`
			animation: 0.7s ${fadeIn};
			animation-fill-mode: forwards;
			visibility: hidden;
		`}

	position: ${({ absolute }) => absolute && 'absolute'};
	top: 0;
	left: 0;
	z-index: 500;
	margin-top: ${({ marginTop }) => marginTop};

	svg {
		width: 50px;
		height: 50px;
		animation: ${spin} 1s linear infinite;
	}

	path {
		fill: var(--human-color--gray-4);
	}

	${({ small }) =>
		small &&
		`
      svg {
        width: 25px;
        height: 25px;
      }

      path {
        fill: var(--human-color--gray);
      }
    `}
	${({ $fullSize }) =>
		$fullSize &&
		`
      padding: 40px;
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`
