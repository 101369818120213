import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'

import { isLocalhost } from '@/lib/constants'
import { isDevelopment, isProduction } from '@/utils'

function getSentryEnvironment(): string {
	switch (import.meta.env.VITE_APP_ENV as string) {
		case 'main':
			return 'production'
		default:
			return 'preview'
	}
}

const PRODUCTION_TRACE_SAMPLE = 0.3

const AUTHENTICATION_ERROR_MESSAGE = /^Authentication is required$/

Sentry.init({
	dsn: import.meta.env.VITE_APP_SENTRY_DSN,
	release: __APP_VERSION__,
	environment: getSentryEnvironment(),
	replaysOnErrorSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	tracesSampleRate: PRODUCTION_TRACE_SAMPLE,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	ignoreErrors: [
		AUTHENTICATION_ERROR_MESSAGE,
		// Safari web extension
		'safari-web-extension://',
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
		// Generic error code from errors outside the security sandbox
		// You can delete this if using raven.js > 1.0, which ignores these automatically.
		'Script error.',
		// Avast extension error
		'_avast_submit',
	],
	autoSessionTracking: !isLocalhost && isProduction,
	beforeSend(event, hint) {
		if (isDevelopment) {
			// eslint-disable-next-line no-console
			console.dir(hint?.originalException || hint?.syntheticException)
			return Promise.resolve(null)
		}

		return event
	},
})
