import { Component } from 'react'
import type { ReactNode } from 'react'

import AppError from '@/components/AppError'

type Props = {
	children: ReactNode | ReactNode[]
}

class ErrorBoundary extends Component<Props> {
	state = {
		error: undefined,
	}

	static getDerivedStateFromError(error: Error): Record<string, Error> {
		return { error }
	}

	render(): ReactNode {
		if (this.state.error) {
			return <AppError error={this.state?.error} fullSize />
		}

		return this.props.children
	}
}

export default ErrorBoundary
