import { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { persistCache } from 'apollo3-cache-persist'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import Modals from '@/modules/Modals'

import i18n from '@/lib/localization'
import apolloClient, { persistOptions } from '@/utils/apollo'

import gliph from '@/assets/gliph.svg'
import Router from '@/router'

import AppSkeleton from './modules/AppSkeleton'

const matchDarkScheme =
	typeof window !== 'undefined'
		? window.matchMedia?.('(prefers-color-scheme: dark)')
		: undefined

const colors = {
	black: '#000000',
	white: '#ffffff',
} as const

export default function App() {
	const [client, setClient] = useState<typeof apolloClient | null>(null)
	const [isDarkTheme, setIsDarkTheme] = useState(
		matchDarkScheme?.matches || false,
	)

	useEffect(() => {
		const changeScheme = ({ matches }: MediaQueryListEvent) =>
			setIsDarkTheme(matches)

		// Add dark/light mode change listener
		matchDarkScheme?.addEventListener?.('change', changeScheme)

		// This will initialize the cache persistor and then set the apolloClient
		persistCache(persistOptions).then(() => void setClient(apolloClient))

		return () => {
			matchDarkScheme?.removeEventListener?.('change', changeScheme)
		}
	}, [])

	// Until the client is ready, let's show a skeleton container
	if (client === null) {
		return <AppSkeleton />
	}

	return (
		<>
			<Helmet>
				<html lang={i18n.language} />
				<link
					rel="mask-icon"
					href={gliph}
					color={isDarkTheme ? '#666' : colors.black}
				/>
				<meta
					name="theme-color"
					content={isDarkTheme ? colors.black : colors.white}
				/>
			</Helmet>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<Modals />
					<Router />
				</BrowserRouter>
			</ApolloProvider>
		</>
	)
}
