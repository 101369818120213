import { HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException } from '@sentry/react'
import fetch from 'cross-fetch'

import { getLoginCallback } from '@/utils/functions'
import { UNAUTHENTICATED } from '@/utils/serverErrors'

async function sha256(message: string) {
	// Based on https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
	const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
	const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
	const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
	const hashHex = hashArray
		// eslint-disable-next-line no-magic-numbers
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('') // convert bytes to hex string
	return hashHex
}

export const errorLink = onError(({ graphQLErrors = [] }) => {
	if (
		graphQLErrors?.some?.(
			({ extensions }) => extensions?.code === UNAUTHENTICATED,
		)
	) {
		window.location.assign(getLoginCallback(window.location))
	} else {
		graphQLErrors?.forEach?.((graphqlError) => {
			switch (graphqlError?.extensions?.code) {
				case 'NOT_FOUND':
					// TODO: we may want to remove elements from cache if they're deleted (not-common)
					break
				default: {
					if (
						typeof graphqlError === 'object' &&
						graphqlError &&
						'originalError' in graphqlError &&
						graphqlError.originalError
					) {
						captureException(graphqlError.originalError)
					}
				}
			}
		})
	}
})

export const httpLink = new HttpLink({
	uri: import.meta.env.VITE_APP_GRAPHQL_URI,
	fetch,
	useGETForQueries: false,
})

export const retryLink = new RetryLink({
	delay: { initial: 300, max: Infinity, jitter: true },
	attempts: { max: 5, retryIf: (error) => Boolean(error) },
})

export const persistedQueriesLink = createPersistedQueryLink({
	sha256,
	useGETForHashedQueries: false,
})
