import { memo } from 'react'
import { createPortal } from 'react-dom'
import { Button, Flex } from '@cretia/components'

import type { BannerOptions } from '@/utils/context'

import * as Styles from './styles'

const MOUNT_NODE_ID = 'notifications-portal'
let notificationsElement = document.getElementById(MOUNT_NODE_ID) as Element
// Mount missing node, e.g. when testing
if (!notificationsElement) {
	notificationsElement = document.createElement('aside')
	notificationsElement.setAttribute('id', MOUNT_NODE_ID)
	document.body.appendChild(notificationsElement)
}

type Props = {
	body: any
	title: any
	options: BannerOptions
}

const DEFAULT_DURATION = 4

function Notification(props: Props) {
	const { body, title, options = { duration: DEFAULT_DURATION } } = props

	return createPortal(
		<Styles.NotificationsContainer>
			<Styles.Container
				key={options.duration}
				duration={options.duration ?? DEFAULT_DURATION}
				data-testid="notification-container"
			>
				<h6>{title}</h6>
				<p>{body}</p>
				{options.action?.onClick && options.action?.label ? (
					<Flex marginTop="8px" justifyContent="flex-end">
						<Button onClick={options.action.onClick} small>
							{options.action.label}
						</Button>
					</Flex>
				) : null}
			</Styles.Container>
		</Styles.NotificationsContainer>,
		notificationsElement,
	)
}

export default memo(Notification)
