import Spinner from '@/components/Spinner'

import { FakeNavBar } from '@/GlobalStyles'

export default function AppSkeleton() {
	return (
		<FakeNavBar>
			<Spinner />
		</FakeNavBar>
	)
}
