import { gql } from '@apollo/client'

const ORGANIZATION_FRAGMENT = gql`
	fragment OrganizationFragment on Organization {
		_id
		apps
		name
		legalName
		taxId
		taxSystem
		information {
			website
		}
		logo {
			url
		}
		logoLight {
			url
		}
		preferences {
			language
			timeZone
			billingEmail
		}
		subscription {
			status
		}
		features
		address {
			street
			number
			city
			suburb
			municipality
			zipCode
			state
			country
		}
	}
`

export default ORGANIZATION_FRAGMENT
