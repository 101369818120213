import styled from 'styled-components'

import SpinnerIcon from '@/assets/spinner.svg?react'

import * as Styles from './styled'

type Props = {
	small?: boolean
	fullSize?: boolean
	translucent?: boolean
	absolute?: boolean
	marginTop?: string
	immediate?: boolean
	color?: string
}

const StyledSpinner = styled(SpinnerIcon)<{ $fill?: string }>`
	path {
		fill: ${({ $fill }) => $fill && $fill};
	}
`

export default function Spinner({
	small,
	color,
	fullSize,
	absolute,
	marginTop,
	translucent,
	immediate = false,
	...props
}: Props) {
	return (
		<Styles.Container
			data-testid="spinner"
			translucent={translucent}
			small={small}
			absolute={absolute}
			$immediate={immediate}
			$fullSize={fullSize}
			marginTop={marginTop}
			{...props}
		>
			<StyledSpinner $fill={color} />
		</Styles.Container>
	)
}
