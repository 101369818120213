import { type ReactNode, useMemo } from 'react'
import { Flex } from '@cretia/components'
import * as Dialog from '@radix-ui/react-dialog'

import ErrorBoundary from '@/components/ErrorBoundary'
import Spinner from '@/components/Spinner'

import * as Styled from './styled'

type Props = {
	title?: string
	children?: ReactNode
	/**
	 * Whether the dialog is open or not
	 * @default true
	 */
	open?: boolean
	onClose?: () => void
	loading?: boolean
	$maxWidth?: number
	$paddingless?: boolean
	$bottomBorderRadius?: boolean
	$top?: boolean
	isPlaceholder?: boolean
}

const DEFAULT_MAX_WIDTH_MODAL = 600

function Modal(props: Props) {
	const {
		title,
		onClose,
		children,
		open = true,
		$top = false,
		loading = false,
		isPlaceholder,
		$paddingless = false,
		$bottomBorderRadius = true,
		$maxWidth = DEFAULT_MAX_WIDTH_MODAL,
	} = props

	const content = useMemo(() => {
		if (isPlaceholder) {
			return <Spinner color="white" />
		}

		return (
			<Styled.Container
				$maxWidth={$maxWidth}
				$bottomBorderRadius={$bottomBorderRadius}
				$paddingless={$paddingless}
				onClick={(evt): void => evt.stopPropagation()}
			>
				<ErrorBoundary>
					{loading ? (
						<Spinner />
					) : (
						<Flex flexDirection="column">
							{Boolean(title) && (
								<>
									<Styled.Title>{title}</Styled.Title>
									<Styled.Hr />
								</>
							)}
							{children}
						</Flex>
					)}
				</ErrorBoundary>
			</Styled.Container>
		)
	}, [
		$bottomBorderRadius,
		$maxWidth,
		$paddingless,
		children,
		isPlaceholder,
		loading,
		title,
	])

	if (open === false) {
		return null
	}

	return (
		<Styled.Root
			data-testid="modal"
			open={open}
			onOpenChange={(isOpen) => (isOpen ? undefined : onClose?.())}
		>
			<Styled.Portal>
				<Styled.Overlay $top={$top}>{content}</Styled.Overlay>
			</Styled.Portal>
		</Styled.Root>
	)
}

export const { Description, Title } = Dialog

export default Modal
