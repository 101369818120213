export const INVALID_PROPS = [
	'paddingTop',
	'paddingLeft',
	'paddingRight',
	'paddingBottom',
	'padding',
	'paddingX',

	'margin',

	'gap',
	'relative',
	'gridColumn',
	'gridTemplateRows',
	'gridTemplateColumns',

	'borderRadius',
	'borderColor',

	'marginTop',
	'marginLeft',
	'marginBottom',

	'flexDirection',
	'flexGrow',

	'maxWidth',
	'minWidth',
	'minHeight',
	'marginY',

	'justifyContent',
	'backgroundColor',

	'alignSelf',
	'alignItems',
	'alignContent',

	// We should prefix these with $
	'isActive',
	'isSingleInput',
	'small',
	'expanded',
	'narrow',
	'wide',
	'showClose',
	'showBackButton',
	'spaceBetween',
	'solid',
	'noIcon',
]

export function shouldForwardProp(prop: string) {
	return !INVALID_PROPS.includes(prop)
}
