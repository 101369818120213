import { Button, Flex } from '@cretia/components'
import { Action, Cancel, Portal, Root } from '@radix-ui/react-alert-dialog'

import alertIcon from '@/assets/icons/alert.svg'
import checkIcon from '@/assets/icons/check.svg'
import errorIcon from '@/assets/icons/error.svg'

import * as Styled from './styled'

type AlertArguments = {
	message: AlertMessage
	buttons?: AlertButton[]
	options?: AlertOptions
}

type Props = {
	alert: AlertArguments
	onClose: () => void
}

function Alert(props: Props) {
	const { alert, onClose } = props

	let alertType: string | undefined
	if (typeof alert.options === 'string') {
		alertType = alert.options
	} else {
		alertType = alert.options?.style
	}

	function renderIcon() {
		switch (alertType) {
			case 'error':
				return <img src={errorIcon} alt="" />
			case 'success':
				return <img src={checkIcon} alt="" />
			default:
				return <img src={alertIcon} alt="" />
		}
	}

	const description =
		typeof alert.message === 'object'
			? alert.message.body
			: typeof alert.options === 'object'
				? alert.options?.description
				: typeof alert.options === 'string'
					? alert.options
					: undefined

	const title =
		typeof alert.message === 'object' ? alert.message.title : alert.message

	return (
		<Root onOpenChange={onClose} defaultOpen>
			<Portal>
				<Styled.Overlay>
					<Styled.Content>
						<Styled.ContentContainer>
							<Styled.Icon aria-hidden="true">{renderIcon()}</Styled.Icon>
							<Flex
								flexGrow={1}
								flexDirection="column"
								gap="var(--space--medium)"
								justifyContent="space-between"
							>
								<Flex gap="var(--space--small)" flexDirection="column">
									<Styled.Title>{title}</Styled.Title>
									{Boolean(description) && (
										<Styled.Description>{description}</Styled.Description>
									)}
								</Flex>
								<Flex gap={8} justifyContent="end">
									{alert.buttons?.map?.(({ text, onClick, style }, index) => (
										<Action key={index} asChild>
											<Button
												destructive={style === 'destructive'}
												solid={style === 'solid'}
												onClick={() => {
													onClick?.()
													props.onClose()
												}}
											>
												{text}
											</Button>
										</Action>
									)) ?? (
										<Cancel asChild>
											<Button onClick={props.onClose} default>
												OK
											</Button>
										</Cancel>
									)}
								</Flex>
							</Flex>
						</Styled.ContentContainer>
					</Styled.Content>
				</Styled.Overlay>
			</Portal>
		</Root>
	)
}

export type AlertButtonProps = {
	text: string
	onClick?: () => void
	style?: 'destructive'
}

export type AlertOptions =
	| string
	| {
			description?: string
			style?: 'error' | 'success' | 'warning'
	  }

export type AlertMessage = string | { title: string; body: string }

export type AlertButton = {
	text: string
	onClick?: () => void
	style?: 'destructive' | 'solid'
}

export default Alert
