export enum SITES_TYPES {
	FLOOR_WALL_UNION_001 = '001',
	WALL_002 = '002',
	ROOF_003 = '003',
	WINDOWS_FRAME_004 = '004',
	DOOR_FRAME_AND_ACCESORIES_005 = '005',
	CRACKS_006 = '006',
	RECORDS_007 = '007',
	STRAINERS_008 = '008',
	FLOOR_WALL_UNION_101 = '101',
	WALL_102 = '102',
	PLANTER_103 = '103',
	WINDOWS_FRAME_104 = '104',
	DOOR_FRAME_AND_ACCESORIES_105 = '105',
	TRASH_BINS_106 = '106',
	RECORDS_107 = '107',
	STOVES_201 = '201',
	OVEN_202 = '202',
	PREPARATION_TABLES_203 = '203',
	SERVICE_TABLES_204 = '204',
	SERVICE_BARS = '205',
	STRAINERS_206 = '206',
	CUPBOARD_207 = '207',
	FLOOR_WALL_UNION_208 = '208',
	WALL_209 = '209',
	ROOF_210 = '210',
	WINDOWS_FRAME_211 = '211',
	DOOR_FRAME_AND_ACCESORIES_212 = '212',
	CRACKS_213 = '213',
	DISH_WASHING_MACHINE_214 = '214',
	SERVICE_MACHINE_215 = '215',
	COLD_ROOM_216 = '216',
	OFICINE_217 = '217',
}
