import { posthog } from 'posthog-js'

import {
	isLocalhost,
	POSTHOG_API_HOST,
	POSTHOG_PROJECT_ID,
} from '@/lib/constants'

if (!isLocalhost) {
	// TODO: check user preferences for anonymous tracking
	posthog.init(POSTHOG_PROJECT_ID, {
		api_host: POSTHOG_API_HOST,
	})
}
