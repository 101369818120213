import { StrictMode } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { StyleSheetManager } from 'styled-components'

import ErrorBoundary from '@/components/ErrorBoundary'

import { AlertProvider } from '@/utils/context'
import { shouldForwardProp } from '@/utils/styled'

import App from '@/App'
import GlobalStyles from '@/GlobalStyles'

export default function Root() {
	return (
		<StrictMode>
			<HelmetProvider>
				<StyleSheetManager shouldForwardProp={shouldForwardProp}>
					<GlobalStyles />
					<ErrorBoundary>
						<AlertProvider>
							<App />
						</AlertProvider>
					</ErrorBoundary>
				</StyleSheetManager>
			</HelmetProvider>
		</StrictMode>
	)
}
