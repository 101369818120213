import {
	Content as RadixContent,
	Description as RadixDescription,
	Overlay as RadixOverlay,
	Title as RadixTitle,
} from '@radix-ui/react-alert-dialog'
import styled from 'styled-components'

import { overlayStyles } from '@/components/Modal/styled'

import devices from '@/utils/devices'

export const Overlay = styled(RadixOverlay)`
	${overlayStyles}
`

export const Title = styled(RadixTitle)`
	font-weight: 600;
	font-size: 1.2rem;
	text-align: left;
`

export const Description = styled(RadixDescription)`
	text-align: left;
	line-height: 1.2;
`

export const Content = styled(RadixContent)<{
	$paddingless?: boolean
	$maxWidth?: number
	$bottomBorderRadius?: boolean
}>`
	box-shadow:
		hsl(206 22% 7% / 35%) 0 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0 10px 20px -15px;
	width: 100%;
	max-width: 1000px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	z-index: var(--z-index--modal);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
	margin-top: auto;

	@media ${devices.tablet} {
		margin: auto;
	}
`

export const ContentContainer = styled.div`
	padding: var(--space--medium) var(--space--large);
	cursor: auto;
	width: 100%;
	margin: auto;
	gap: var(--space--medium);
	display: flex;
	max-width: 500px;
	border-radius: 20px;
	box-shadow: var(--box-shadow--popup);
	border: 1px solid var(--human-color--gray-5);
	background-color: var(--human-color--background);
	align-items: center;
`

export const Icon = styled.div`
	width: 60px;
	height: 60px;
	flex-shrink: 0;
`
