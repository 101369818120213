import { format } from 'date-fns'
import { formatRelative } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

const locales = { en: enUS, es }
const MINUTES_IN_HOUR = 60
const MILLISECONDS = 1000

function formatRelativeToNow(
	relativeDate: Date,
	{ locale = 'en' }: { locale?: keyof typeof locales } = {},
) {
	return formatRelative(relativeDate, new Date(), {
		locale: locales[locale],
	})
}

const now = new Date()

function formatReadableDate(
	date: string | number | null | undefined,
	t: (str: string) => string,
): string {
	const dateArr = format(getSafeServerDate(date), `d MMMM yyyy`).split(' ')
	const formattedDate = `
    ${dateArr[0]} ${t(dateArr[1].toLowerCase())} ${dateArr[2]}
  `

	return formattedDate
}

function getSafeServerDate(
	potentialDate: string | number | undefined | null,
): Date {
	if (potentialDate) {
		const numberDate = Number(potentialDate)

		let date: Date
		// If it's a number string date e.g. '1615254957213'
		if (!isNaN(numberDate)) {
			date = new Date(numberDate)
		} else {
			date = new Date(potentialDate) // If it's an ISO Date
		}

		if (date instanceof Date && !isNaN(date as any)) {
			return date
		}
		return now
	}

	return now
}

export function getDurationInText({
	minutes,
	ms,
}: {
	minutes?: number
	ms?: number
}): string {
	const durationInMinutes = ms ? ms / MILLISECONDS / MINUTES_IN_HOUR : minutes

	if (!durationInMinutes) return ''

	return durationInMinutes > MINUTES_IN_HOUR
		? `${Math.ceil(durationInMinutes / MINUTES_IN_HOUR)}h ${Math.round(
				durationInMinutes % MINUTES_IN_HOUR,
			)}m`
		: `${Math.round(durationInMinutes)}m`
}

export { formatRelativeToNow, formatReadableDate, getSafeServerDate }
