import { gql } from '@apollo/client'

const getLocalFields = gql`
	query GetLocalFields {
		oAuth @client
		selectedFacility @client
	}
`

export default getLocalFields
