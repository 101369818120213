import type { TypePolicy } from '@apollo/client'
import { InMemoryCache, makeVar } from '@apollo/client'
import deepmerge from 'deepmerge'

import type { SWEventType } from '@/utils/serviceWorker'

const Query: TypePolicy = {
	fields: {
		// Local status with vars
		connectionStatus: {
			read: () => connectionStatusVar(),
		},
		updateAvailable: {
			read: () => updateAvailableVar(),
		},

		// Local fields with cache
		oAuth: {
			read: (existing: string | undefined) => existing ?? null,
		},
		selectedFacility: {
			read: (existing: string | undefined) => existing ?? null,
		},
		file: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'File', id: args?.id })
			},
		},
		unit: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Unit', id: args?.id })
			},
		},
		service: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Service', id: args?.id })
			},
		},
		facility: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Facility', id: args?.id })
			},
		},
		client: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Client', id: args?.id })
			},
		},
		contact: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Contact', id: args?.id })
			},
		},
		employee: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Employee', id: args?.id })
			},
		},
		product: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Product', id: args?.id })
			},
		},
		role: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Role', id: args?.id })
			},
		},
		invoice: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Invoice', id: args?.id })
			},
		},
		group: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Group', id: args?.id })
			},
		},
		issue: {
			read(existing: string | undefined, { args, toReference }) {
				return existing ?? toReference({ __typename: 'Issue', id: args?.id })
			},
		},
	},
}

const FacilityConnection = {
	fields: {
		nodes: {
			read: (nodes = []) => nodes,
		},
	},
	keyFields: ['_key'],
}

const Contact: TypePolicy = {
	fields: {
		facilities: {
			merge: (existing, incoming) => ({ ...existing, ...incoming }),
		},
	},
}

const OrgPreferences: TypePolicy = {
	merge: (existing, incoming) => deepmerge(existing, incoming),
}

const Organization: TypePolicy = {
	fields: {
		address: {
			merge: (existing, incoming) => deepmerge(existing, incoming),
		},
	},
}

const CONNECTIONS = [
	'ActivityConnection',
	'AreaConnection',
	'ContactConnection',
	'EmployeeConnection',
	'FacilityConnection',
	'FileConnection',
	'IssueConnection',
	'ProductConnection',
	'RoleConnection',
	'ServiceConnection',
	'TransportConnection',
	'UnitConnection',
	'UnitServiceConnection',
	'UserConnection',
	'InvoiceConnection',
]

export const connectionStatusVar = makeVar<'offline' | 'connected' | null>(null)
export const updateAvailableVar = makeVar<SWEventType | null>(null)

export default new InMemoryCache({
	typePolicies: {
		Query,
		Contact,
		Organization,
		OrgPreferences,
		FacilityConnection,
		...Object.fromEntries?.(
			CONNECTIONS.map((key) => [key, { keyFields: ['_key'] }]),
		),
	},
})
