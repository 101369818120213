import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { isDevelopment } from '@/utils'

import en from './en-US/default.json'
import es from './es-MX/default.json'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		compatibilityJSON: 'v3',
		resources: {
			en: { translation: en },
			es: { translation: es },
		},
		debug: isDevelopment,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
