/* eslint-disable max-lines */
import { Helmet } from 'react-helmet-async'
import { createGlobalStyle } from 'styled-components'
import styled, { css } from 'styled-components'

import devices, { sizes } from '@/utils/devices'

import jumpIcon from '@/assets/jump.svg'

import '@cretia/components/styles/global.css'

const globalStyle = createGlobalStyle`
  *,
  html,
  body,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  option,
  select,
  span,
  ul {
    margin: 0;
    border: none;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;

    svg {
      height: 1em;
      stroke-width: 2.5;
      transform: translateY(2px);
    }
  }
  
  h1 {
    font-weight: 600;
  }

  p {
    line-height: 1.47059;
  }

  option[disabled] {
    opacity: 0.5;
  }

  hr {
    width:100%;
    height: 1px;
    background-color: var(--human-color--gray-6);
    margin: 24px 0;
  }

  html  {
    background-color: var(--human-color--background);
    overflow: hidden;
  }

  html, body, #root {
    min-height: 100vh;
    min-height: 100dvh;
  }

  body,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    quotes: '“' '”';
    font-size: 1rem;
    letter-spacing: -0.022em;
    font-style: normal;
    color: var(--human-color--text);
    direction: ltr;
  }

  button,
  input,
  textarea,
  select {
    color: var(--human-color--text);
    background-color: transparent;
    appearance: none;
    font-size: 1rem;
    outline: none;
    &:focus:not([readonly]) {
      border-color: var(--human-color--blue);
      box-shadow: 0 0 0 1.5px var(--human-color--blue);
    }
  }

  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
  }

  input,
  textarea,
  select {
    appearance: none;
    font-size: 1rem;
    padding: 6px 4px;
    border-radius: var(--border_radius--medium);
    color: var(--human-color--text);

    &[readonly] {
      color: var(--human-color--text-secondary);
    }
  }

	.react-select {
		.react-select__multi-value {
			display: inline-flex;
			justify-content: center;
			padding: 0 4px;
		}

		.react-select__menu {
			border-radius: var(--border_radius--medium);
			overflow: hidden;
		}
	}

  input[type="radio"], input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background-color: var(--human-color--background);
    border: 1px solid var(--human-color--gray-3);
    padding: 0;
    border-radius: 50%;

    &:hover:not([readonly]), &:focus:not([readonly]) {
      border: 1px solid var(--human-color--blue);
      box-shadow: 0 0 0 1.5px var(--human-color--blue);
    }

    &[checked], &.checked {
      background-color: var(--human-color--blue);
      border-color:  var(--human-color--blue);
    }
  }

  input[type="range"] {
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      overflow: hidden;
      -webkit-appearance: none;
      
      &::-webkit-slider-runnable-track {
        height: 2px;
        background: var(--human-color--gray);
      }
      
      &::-webkit-slider-thumb {
        z-index: 1;
        margin-top: -7px;
        box-shadow: 0 0 0 1px var(--human-color--background);
      }
    }
  }

  input[type="checkbox"] {
    border-radius: 20%;
    position: relative;
    &:before, &:after {
      content:'';
      display: block;
      position: absolute;
      width: 85%;
      height: 2px;
      background-color: white;
      transform: rotate(-45deg) translate(-3px,7px);
    }
    &:after {
      width: 30%;
      transform: rotate(45deg) translate(8px, 6px);
    }
  }
  
  [draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }

  .unit-tooltip {
    background-color: transparent !important;
    border: none !important;
    color: var(--human-color--white) !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    
    -webkit-text-stroke: 0.5px black;
    -webkit-text-fill-color: white;

    &::before, &::after {
      display: none !important;
    }
  }

  .unit-popup {
    padding: 0;
    .leaflet-popup-content-wrapper {
      background-color: var(--human-color--background);
      .leaflet-popup-content {
        padding: 12px;
        margin: 0;
        p {
          color: var(--human-color--text);
          margin: 4px 0;
        }
      }
    }
    .leaflet-popup-tip {
      background: var(--human-color--background) !important;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .gliph {
    width: 10px;
    height: 10px;
    margin: 2px 0 0 8px;
    display: block;
    border-bottom: 1.5px solid var(--human-color--blue);
    border-left: 1.5px solid var(--human-color--blue);
    transform: rotate(-45deg);
  }

  .DayPicker {
    width: 100%;
    background-color: var(--human-color--background);

    &.range-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      border-radius: 0;
      &.DayPicker-Day--start {
        border-top-left-radius: 17.5px;
        border-bottom-left-radius: 17.5px;
      }
      &.DayPicker-Day--end {
        border-top-right-radius: 17.5px;
        border-bottom-right-radius: 17.5px;
      }
    }

    .DayPicker-Months {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
      gap: 16px;
    }
    .DayPicker-Month {
      margin: 0;
    }
    .DayPicker-Day {
      position: relative;
      background-color: transparent;
      :hover {
        opacity: 0.8;
      }
    }
    .DayPicker-Day--today {
      color: var(--human-color--blue);
    }
    .DayPicker-Body {
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: var(--human-color--blue) !important;
        color: white;
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: var(--human-color--blue);
      }
    }
  }

  [role="tablist"] {
    display: flex;
    margin-bottom: 0;
    background-color: transparent !important;
    padding: 0 var(--space--medium) var(--space--small);
    border-bottom: 0 solid var(--human-color--gray-3);
    align-items: center;
    
    [role="tab"] {
      height: 28px;
      font-size: 0.9rem;
      font-weight: 500;
      position: relative;
      border-radius: 9999px;
      padding: 0 var(--space--medium);

      &[data-state="active"],
      &[data-selected] {
        color: white;
        background-color: var(--human-color--blue);
      }
    }

    @media ${devices.tablet}  {
      padding: 0;

      [role="tab"] {
        height: 100%;
        font-weight: 600;
        padding: 10px 8px;
        font-size: inherit;
        border-radius: 8px 8px 0 0;
  
        &:focus {
          background-color: var(--human-color--overlay-blue);
          box-shadow: none;
        }
        &[data-disabled] {
          opacity:0.5;
        }
  

        &[data-state="active"],
        &[data-selected] {
          background-color: initial;
          color: var(--human-color--blue);
        }

        &[data-state="active"]:before {
          left: 0;
          bottom: 0;
          height: 3px;
          width: 100%;
          content: '';
          position: absolute;
          background-color: var(--human-color--blue);
        }
      }
    }
  }

  button[data-radix-collection-item] {
    border-radius: var(--border_radius--medium);
    :hover {
      background-color: var(--human-color--overlay-blue);
    }
  }
  
  [data-radix-popper-content-wrapper] {
    z-index: calc(var(--z-index--modal) + 1) !important;
  }

  .search-popover {
		width: 100% !important;
    position: relative !important;
    transform: none !important;
  }

  .shadow-popup {
    z-index: 10000;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    appearance: textfield; /* Firefox */
  }
`

const ClientCell = styled.div`
	display: flex !important;
	align-items: center;
`

const SplitView = {
	Actions: styled.div`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 12px;
		min-width: 250px;
		height: var(--size--heading);
		border-right: 1px solid var(--human-color--gray-6);
		background-color: var(--human-color--background);
		z-index: 3;
	`,
	Master: styled.div<{ $withoutActionBar?: boolean }>`
		min-height: 0;
		overflow-y: auto;
		display: block;

		@media ${devices.tablet} {
			border-right: 1px solid var(--human-color--gray-6);
			grid-area: item-list;
			grid-row-start: ${({ $withoutActionBar }) =>
				$withoutActionBar && 'search-bar-start'};
		}
	`,
	Detail: styled.div<{ full?: boolean }>`
		min-height: 0;
		min-width: 0;
		display: flex;
		flex-direction: column;

		@media ${devices.tablet} {
			position: relative;
			grid-column-start: ${({ full }): string =>
				full ? 'search-bar-start' : 'body-start'};
			grid-row: ${({ full }) => !full && 'search-bar / body'};
		}
	`,
	ListItemHeader: styled.div`
		padding: 16px 16px 4px;
		color: var(--human-color--text-tertiary);
		text-transform: uppercase;
		font-size: 0.7rem;
		letter-spacing: 1px;
		font-weight: 600;
	`,
	ListItem: styled.div<{
		$jump?: boolean
		$alternate?: boolean
		$inactive?: boolean
		disabled?: boolean
	}>`
		cursor: default;
		display: flex;
		flex-direction: column;
		padding: var(--space--small) var(--space--medium);
		background: ${({ $jump }) =>
			$jump && `url(${jumpIcon}) no-repeat right 8px center`};
		background-size: 20px;
		background-color: ${({ $alternate }) =>
			$alternate && 'var(--human-color--gray-7)'};
		opacity: ${({ $inactive, disabled }) => ($inactive || disabled) && '0.5'};
		pointer-events: ${({ disabled }) => disabled && 'none'};
		font-size: 1rem;
		width: 100%;

		> strong,
		> p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
		}

		&:hover,
		&:focus {
			text-decoration: none;
			opacity: 0.75;
		}

		@media ${devices.tablet} {
			&.active {
				background-color: var(--human-color--blue);
				* {
					color: var(--human-color--white) !important;
				}
			}
		}

		&.align_right {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-end;
		}
	`,
}

const ModuleContent = styled.div<{
	$noPadding?: boolean
	$full?: boolean
	$noScrollBehavior?: boolean
	$hasBottomEditor?: boolean
}>`
	height: 100%;
	padding: ${({ $noPadding }) => ($noPadding ? '0 !important' : '16px')};

	> h1 {
		margin: 0 0 16px;
	}

	> h2 {
		margin-top: 16px;
	}

	> h3 {
		margin-bottom: 8px;
	}

	${({ $full }) =>
		$full &&
		css`
			overflow-y: auto;
		`}

	${({ $noScrollBehavior }) =>
		$noScrollBehavior &&
		css`
			overscroll-behavior: none;
		`}

	${({ $hasBottomEditor }) =>
		$hasBottomEditor &&
		css`
			padding-bottom: var(--size--bottom_editor);
		`}
`

type AppContainerOptions = {
	$hasSplitView?: boolean
	$detailFocused?: boolean
	$full?: boolean
	$oneView?: boolean
}

const AppContainer = styled.div<AppContainerOptions>`
	display: grid;
	min-height: 0;
	max-height: 100vh;
	max-height: 100dvh;
	grid-auto-rows: auto 1fr;

	${({ $full }) => $full && 'overflow-y: auto;'}

	@media ${devices.tablet} {
		position: relative;
		grid-template-rows: var(--size--heading) 1fr;
	}

	${({ $oneView }) => $oneView && 'grid-template-rows: 1fr !important;'}

	${({ $hasSplitView, $detailFocused }) =>
		$hasSplitView
			? `
          grid-template-columns: 1fr;

          @media ${devices.tablet} {
            grid-template-columns: 275px 1fr;
            grid-template-areas: 'search-bar search-bar' 'item-list body';
          }

          @media (max-width: ${sizes.tablet}) {
            ${SplitView.Detail} {
              display: ${!$detailFocused ? 'none' : ''};
            }
            ${SplitView.Master}, ${SplitView.Actions} {
              display: ${$detailFocused ? 'none !important' : ''};
            }
          }
        `
			: `
          ${SplitView.Detail} {
            grid-column-start: initial;
            grid-row: initial;
          }
        `}
`

function ModuleContainer({
	title,
	children,
	...props
}: {
	title?: string
	children: any
} & AppContainerOptions) {
	return (
		<>
			{Boolean(title) && (
				<Helmet titleTemplate="%s - Cretia">
					<title>{title}</title>
				</Helmet>
			)}
			<AppContainer {...props}>{children}</AppContainer>
		</>
	)
}

const DetailActions = styled.div<{ spaceBetween?: boolean }>`
	z-index: 1002;
	bottom: 0;
	display: grid;
	position: absolute;
	border-top: 1px solid var(--human-color--gray-6);
	width: 100%;
	background-color: var(--human-color--background-alternate);
	padding: 0 16px;
	align-items: center;
	height: var(--size--heading);
	grid-auto-columns: min-content;
	grid-auto-flow: column;
	justify-content: ${({ spaceBetween }): string =>
		spaceBetween ? 'space-between' : 'flex-end'};
	gap: var(--space--medium);
`

const DetailActionsGroup = styled.div`
	display: grid;
	grid-auto-columns: min-content;
	grid-auto-flow: column;
	gap: 12px;
`

const Table = {
	Container: styled.table`
		width: 100%;
	`,
	Row: styled.tr<{
		active?: boolean
		selectable?: boolean
		inactive?: boolean
	}>`
		padding: 4px 0;
		transition: 0.1s background-color;
		background-color: ${({ active }): any =>
			active && 'var(--human-color--blue) !important'};
		color: ${({ active, inactive }): string =>
			active
				? 'var(--human-color--white)'
				: inactive
					? 'var(--human-color--text-secondary)'
					: 'var(--human-color--text)'};
		cursor: ${({ selectable }) => selectable && 'pointer'};

		a:hover {
			text-decoration: none;
		}
	`,
	Head: styled.thead`
		position: sticky;
		top: 0;
		background-color: var(--human-color--background);
		z-index: 2;
	`,
	Header: styled.th`
		padding: var(--space--small) var(--space--medium);
		font-weight: 600;
	`,
	Body: styled.tbody`
		tr:nth-child(odd) {
			background-color: var(--human-color--gray-7);
		}

		p {
			line-height: 1;
		}
	`,
	Data: styled.td<{
		$sticky?: boolean
		padding?: boolean
		childPadding?: boolean
	}>`
		padding: ${({ padding }): string =>
			padding ? 'var(--space--small) var(--space--medium)' : '0'};
		font-size: 1rem;
		vertical-align: middle;
		z-index: 3;

		> * {
			padding: ${({ padding }) =>
				padding ? undefined : 'var(--space--small) var(--space--medium)'};
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		a:hover {
			color: var(--human-color--blue);
		}

		${({ $sticky }) =>
			$sticky &&
			css`
				--transparent: color-mix(
					in srgb,
					var(--human-color--background) 0%,
					transparent
				);

				position: sticky;
				right: 0;
				background: linear-gradient(
					90deg,
					var(--transparent),
					var(--human-color--background) 30%,
					var(--human-color--background) 35%
				);
			`}
	`,
}

const DetailButton = styled.div<{ $jump?: boolean }>`
	border-radius: 0;
	background-color: var(--human-color--gray-6);
	color: var(--human-color--text);
	display: block;
	width: 100%;
	position: relative;
	${({ $jump }) =>
		$jump &&
		`
      background-image: url(${jumpIcon});
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 20px;
    `};

	i {
		position: absolute;
		width: 41px;
		height: 41px;
		top: 0;
		margin: 0;
		left: 0;
		margin: 2px;
	}

	> p,
	> strong {
		display: block;
		width: calc(100% - 48px);
		padding: var(--space--small) 0;
		margin-left: 48px;
		border-bottom: 1px solid var(--human-color--gray-5);
	}
`

const DetailContainer = styled.div`
	margin: 16px 0;

	${DetailButton}:first-child {
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	${DetailButton}:last-child {
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
		> p,
		> strong {
			border-bottom: 0;
		}
	}
`
const dotTypeColors = {
	error: 'var(--human-color--red)',
	warning: 'var(--human-color--yellow)',
	alert: 'var(--human-color--orange)',
	inform: 'var(--human-color--teal)',

	open: 'var(--human-color--green)',
	overdue: 'var(--human-color--pink)',
	closed: 'var(--human-color--blue)',
	cancelled: 'var(--human-color--gray)',
}

const DotIndicator = styled.span<{
	$type:
		| 'warning'
		| 'error'
		| 'alert'
		| 'inform'
		| 'open'
		| 'overdue'
		| 'closed'
		| 'cancelled'
}>`
	height: 6px;
	width: 6px;
	background-color: ${({ $type }): string => dotTypeColors[$type]};
	border-radius: 50%;
	display: block;
`

const DotsContainer = styled.div`
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
`

const FakeNavBar = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10vh 0;
	min-height: 100vh;
	min-height: 100dvh;

	&:after {
		top: 0;
		bottom: 0;
		left: 0;
		position: fixed;
		content: '';
		display: block;
		height: 100%;
		width: 49px;
		background-color: var(--human-color--background-alternate);
		border-right: 1px solid var(--human-color--gray-6);
	}
`

const TabsContainer = styled.div`
	top: 0;
	z-index: 1;
	display: flex;
	position: sticky;
	overflow-x: auto;
	flex-direction: column;
	background-color: var(--human-color--background);
	border-bottom: 1px solid var(--human-color--gray-6);

	@media ${devices.tablet} {
		flex-direction: row;
	}
`

export const ParagraphExtra = styled.p<{
	$oneLine?: boolean
	$maxWidth?: boolean
}>`
	font-size: 0.85rem;
	font-weight: 500;
	opacity: 0.6;

	${({ $oneLine }) =>
		$oneLine &&
		css`
			white-space: nowrap;
			overflow: hidden;
			max-width: 100%;
			text-overflow: ellipsis;
		`}
	${({ $maxWidth }) =>
		$maxWidth &&
		css`
			max-width: 170px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`}
`

export {
	Table,
	FakeNavBar,
	//
	SplitView,
	ModuleContainer,
	DetailActions,
	DetailActionsGroup,
	ModuleContent,
	DetailButton,
	DetailContainer,
	DotIndicator,
	DotsContainer,
	ClientCell,
	TabsContainer,
}

export default globalStyle
