export const SERVER_ERROR = 'SERVER_ERROR'
export const NOT_FOUND = 'NOT_FOUND'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const INVALID_TOKEN = 'INVALID_TOKEN'
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
export const INVALID_ORGANIZATION = 'INVALID_ORGANIZATION'
export const USER_NOT_REGISTERED = 'USER_NOT_REGISTERED'
export const USER_DEACTIVATED = 'USER_DEACTIVATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const FORBIDDEN = 'FORBIDDEN'
