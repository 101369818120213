import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

export default function Redirect({ to, type }: any) {
	const params = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		const pathToRedirect = `${to}/${params['*'] ?? params.id}?type=${type}`

		navigate(pathToRedirect)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}
