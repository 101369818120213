import { lazy, Suspense } from 'react'
import { ErrorBoundary } from '@sentry/react'

import AppError from '@/components/AppError'
import Modal from '@/components/Modal'

import { modals } from '@/lib/constants'
import { popModal, useModals } from '@/utils/functions'

const ShareModal = lazy(() => import('@/modules/ShareModal'))
const CreateClient = lazy(() => import('@/modules/CreateCompanyModal'))
const CreateContact = lazy(() => import('@/modules/CreateContactModal'))
const CreateService = lazy(() => import('@/modules/CreateServiceModal'))
const EditInvoice = lazy(() => import('@/modules/InvoiceModal'))
const CreateProduct = lazy(() => import('@/modules/CreateProductModal'))
const CreateEmployee = lazy(() => import('@/modules/CreateEmployeeModal'))
const CreateFacility = lazy(() => import('@/modules/CreateFacilityModal'))
const CreateEmployeeUser = lazy(
	() => import('@/modules/CreateEmployeeUserModal'),
)
const CreatePaymentInvoice = lazy(
	() => import('@/modules/CreatePaymentInvoiceModal'),
)
const CreatePaymentLink = lazy(() => import('@/modules/CreatePaymentLinkModal'))
const CreateTransaction = lazy(() => import('@/modules/CreateTransactionModal'))

const modalMapping = {
	[modals.share]: ShareModal,
	[modals.clients]: CreateClient,
	[modals.contacts]: CreateContact,
	[modals.services]: CreateService,
	[modals.invoices]: EditInvoice,
	[modals.facilities]: CreateFacility,
	[modals.products]: CreateProduct,
	[modals.employee]: CreateEmployee,
	[modals.employees]: CreateEmployeeUser,
	[modals.payments]: CreatePaymentInvoice,
	[modals.links]: CreatePaymentLink,
	[modals.transactions]: CreateTransaction,
} as const

export default function Modals() {
	const modals = useModals()

	return modals.map(([modalKey, modalProps], index) => {
		const ModalComponent = modalMapping[modalKey]

		function onCompleted(...args: unknown[]) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			modalProps.onCompleted?.(...args)
			popModal()
		}

		return (
			<ErrorBoundary
				key={index}
				fallback={
					<Modal key={modalKey + index} onClose={popModal}>
						<AppError />
					</Modal>
				}
			>
				<Suspense
					fallback={
						<Modal key={modalKey + index} onClose={popModal} isPlaceholder />
					}
				>
					<ModalComponent
						{...(modalProps as any)}
						onCompleted={onCompleted}
						onClose={popModal}
					/>
				</Suspense>
			</ErrorBoundary>
		)
	})
}
