import { isLocalhost } from '@/lib/constants'
import { isProduction } from '@/utils'

const HTTP_NOT_FOUND = 404

type Config = {
	onRegister?: (event: { data: { type: SWEventType }; error?: unknown }) => void
}

async function registerValidSW(swUrl: string, config?: Config) {
	try {
		const registration = await navigator.serviceWorker.register(swUrl)

		if (registration.waiting) {
			config?.onRegister?.({ data: { type: 'waiting' } })
		}

		registration.onupdatefound = () => {
			const installingWorker = registration.installing
			if (installingWorker == null) return

			installingWorker.onstatechange = () => {
				if (installingWorker.state === 'installed') {
					if (navigator.serviceWorker.controller) {
						config?.onRegister?.({ data: { type: 'newContent' } })
					} else {
						config?.onRegister?.({ data: { type: 'cached' } })
					}
				}
			}
		}
	} catch (error) {
		config?.onRegister?.({ data: { type: 'offline' }, error })
	}
}

async function checkValidServiceWorker(swUrl: string, config?: Config) {
	try {
		// Check if the service worker can be found. If it can't reload the page.
		const response = await fetch(swUrl, {
			headers: { 'Service-Worker': 'script' },
		})

		// Ensure service worker exists, and that we really are getting a JS file.
		const contentType = response.headers.get('content-type')
		if (
			response.status === HTTP_NOT_FOUND ||
			(contentType != null && contentType.indexOf('javascript') === -1)
		) {
			// No service worker found. Probably a different app. Reload the page.
			navigator.serviceWorker.ready.then((registration) => {
				registration.unregister().then(() => {
					window.location.reload()
				})
			})
		} else {
			// Service worker found. Proceed as normal.
			registerValidSW(swUrl, config)
		}
	} catch (error) {
		config?.onRegister?.({ data: { type: 'offline' }, error })
	}
}

export type SWEventType =
	| 'noSupport'
	| 'offline'
	| 'waiting'
	| 'newContent'
	| 'cached'

export function register(config?: Config): void {
	if (isProduction && 'serviceWorker' in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(window.location.href)

		if (publicUrl.origin !== window.location.origin) {
			// See https://github.com/facebook/create-react-app/issues/2374
			return
		}

		window.addEventListener('load', () => {
			const swUrl = `/service-worker.js`

			if (isLocalhost) {
				checkValidServiceWorker(swUrl, config)
			} else {
				registerValidSW(swUrl, config)
			}
		})
	} else {
		config?.onRegister?.({ data: { type: 'noSupport' } })
	}
}

export async function skipWaiting({
	onActivated,
}: {
	onActivated?: () => void
}): Promise<void> {
	if ('serviceWorker' in navigator) {
		const registration = await navigator.serviceWorker.ready

		registration.waiting?.postMessage({ type: 'SKIP_WAITING' })

		registration.waiting?.addEventListener('statechange', (event: any) => {
			switch (event.target.state) {
				case 'activated':
					onActivated?.()
					break
				default:
					break
			}
		})
	}
}
