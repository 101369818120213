import { keyframes } from 'styled-components'
import styled from 'styled-components'

const slideFromTop = keyframes`
  0% {
    transform: translateY(-150%);
  }
  
  10% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-150%);
  }
`

const NotificationsContainer = styled.div`
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: var(--z-index--notifications);
	display: flex;
	justify-content: center;
	padding: 16px;
`

const Container = styled.div<{ duration: number }>`
	pointer-events: auto;
	transform: translateY(-150%);
	padding: 16px;
	width: 100%;
	max-width: 500px;
	border-radius: var(--border_radius--medium);
	animation: ${({ duration }) => duration}s ${slideFromTop};
	border: 1px solid var(--human-color--gray-5);
	background-color: var(--human-color--background);
	box-shadow: var(--box-shadow--popup);
`

export { NotificationsContainer, Container }
