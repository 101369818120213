import { gql } from '@apollo/client'

import OrganizationFragment from '@/lib/graphql/fragments/organization'

const userFragment = gql`
	fragment UserFragment on User {
		_id
		name
		surname
		email
		phoneNumber
		company {
			_id
			name
		}
		photo {
			url
		}
		preferences {
			language
			timeZone
			privacy {
				tracking {
					performance
					product
					crash
				}
			}
		}
		organization {
			...OrganizationFragment
		}
		facilities {
			_key
			nodes {
				_id
				name
			}
		}
		isEmailBouncing
	}
	${OrganizationFragment}
`

export default userFragment
