import { Button } from '@cretia/components'
import styled from 'styled-components'

export const Form = styled.form`
	max-width: 400px;
	margin: 24px auto 0;
	text-align: left;
	font-size: 0.9rem;

	p {
		margin-bottom: 8px;
	}

	${Button} {
		margin-top: 8px;
		margin-left: auto;
		display: block;
	}
`

export const Container = styled.div<{ $fullSize?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${({ $fullSize }): string => ($fullSize ? '100vh' : '100%')};
	/* height: ${({ $fullSize }): string => ($fullSize ? '100dvh' : '100%')}; */

	text-align: center;
	h2 {
		margin-bottom: 8px;
	}

	.rat {
		display: block;
		width: 200px;
		margin: 0 auto;
		margin-top: 16px;
	}

	button,
	a {
		color: var(--human-color--blue);
		font-weight: 600;
		&:hover {
			text-decoration: underline;
		}
	}

	p,
	button,
	a {
		margin-top: 30px;
	}
`

export const ReportMessage = styled(Container)`
	font-size: 0.9rem;
	color: gray;
	margin: 0 auto;
	margin-top: 30px;
	max-width: 300px;
`
