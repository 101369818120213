import { setContext } from '@apollo/client/link/context'
import i18next from 'i18next'

export function getAuthorizationHeader() {
	const token = window?.localStorage?.getItem(ACCESS_TOKEN_KEY)
	return token ? `Bearer ${token}` : undefined
}

export const ACCESS_TOKEN_KEY = '@cretia/access-token'

export default setContext((_, prevContext) => {
	const { headers = {} } = prevContext

	const token = window?.localStorage?.getItem(ACCESS_TOKEN_KEY)

	const defaultHeaders = {
		'accept-language': i18next.language,
	}

	if (!token) {
		return { headers: { ...headers, ...defaultHeaders } }
	}

	return {
		headers: {
			...headers,
			...defaultHeaders,
			authorization: getAuthorizationHeader(),
		},
	}
})
