import { useCallback, useState } from 'react'
import type { ApolloError } from '@apollo/client'
import type { GraphQLError } from 'graphql'
import queryString from 'query-string'

import type { JWT_NAMESPACE } from '@/lib/constants'
import {
	AREA_TYPE_ENUM,
	LANGUAGE_KEY,
	ORG_KEY,
	SERVICE_TYPE_ENUM,
	SPECIFIC_UNIT_STATUSES,
} from '@/lib/constants'
import { FileAccess } from '@/lib/generated/gql'
import GET_LOCAL_FIELDS from '@/lib/graphql/queries/getLocalFields'
import GET_SELF from '@/lib/graphql/queries/getSelf'
import { cachePersistor, client as apolloClient } from '@/utils/apollo'
import { ACCESS_TOKEN_KEY } from '@/utils/apollo/authContext'

function cleanEmail(email: string): string {
	return email.toLowerCase().replace(' ', '')
}

/**
 * Only used for test purposes
 */
const DEFAULT_ORG = import.meta.env.VITE_APP_DEFAULT_ORG

function sortClientsByName<T extends { name: string }>(
	clients: T[] = EMPTY_ARRAY,
): T[] {
	return [...clients].sort((a, b) => a?.name?.localeCompare(b?.name))
}

export function getAssetDomain(access: FileAccess) {
	if (access === FileAccess.Private) {
		return import.meta.env.VITE_APP_PRIVATE_ASSETS_DOMAIN
	}
	if (access === FileAccess.Secret) {
		return import.meta.env.VITE_APP_SECRET_ASSETS_DOMAIN
	}

	return (
		import.meta.env.VITE_APP_PUBLIC_ASSETS_DOMAIN ?? 'https://cdn.cretia.app'
	)
}

function extractIds(
	objects: undefined | null | any[],
	key = 'id',
	otherKey = '_id',
): string[] {
	return objects?.map((object) => object[key] || object[otherKey]) ?? []
}

function getOptions(all: any[] = [], key = 'label'): any[] {
	return all.map(({ _id, name }) => ({
		[key]: name,
		value: String(_id),
	}))
}

function dataURItoFile(dataURI: string, fileName: string): File {
	const byteString = window.atob(dataURI.split(',')[1])

	const [mimeString] = dataURI.split(',')[0].split(':')[1].split(';')

	const ab = new ArrayBuffer(byteString.length)
	const ia = new Uint8Array(ab)
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}
	const blob = new Blob([ab], { type: mimeString })

	return new File([blob], fileName)
}

const CURRENT_DOMAINS = ['cretia', 'teia']
const IGNORED_SUBDOMAINS = ['app', 'beta']
function getOrganizationSlug(): [string | undefined, boolean] {
	// This enables passing the org at the URL parameter to skip the manual input
	if (window?.location.search) {
		const org = queryString.parse(window.location.search)?.org
		if (org) return [org as string, false]
	}

	if (localStorage.getItem(ORG_KEY)) {
		return [String(localStorage.getItem(ORG_KEY)), false]
	}
	// eslint-disable-next-line no-magic-numbers
	const [subdomain, domain] = window.location.host.split('.').slice(-3)
	let org = CURRENT_DOMAINS.includes(domain) ? subdomain : undefined

	if (!org || IGNORED_SUBDOMAINS.includes(org)) {
		org = DEFAULT_ORG
	} else {
		return [org, true]
	}

	return [org, false]
}

function resetSessionData({
	removeAccessToken = true,
}: { removeAccessToken?: boolean } = {}): Promise<any> {
	if (removeAccessToken) {
		localStorage.removeItem(ACCESS_TOKEN_KEY)
	}

	return Promise.allSettled([
		apolloClient.clearStore(),
		apolloClient.resetStore(),
		cachePersistor.purge(),
	])
}

function useToggle(
	initialState = false,
): [boolean, (forceValue?: any) => void] {
	const [state, setState] = useState<boolean>(initialState)

	function toggleState(forceValue?: boolean) {
		if (typeof forceValue === 'boolean') {
			setState(forceValue)
		} else {
			setState((prev) => !prev)
		}
	}

	return [state, toggleState]
}

function hasGraphQLErrorCode(graphQLErrors = [], errorCode: string): boolean {
	return graphQLErrors.some(
		({ extensions }: GraphQLError) => extensions?.code === errorCode,
	)
}

export function getInitials(person: { name: string; surname: string }) {
	return person.name.charAt(0) + person.surname.charAt(0)
}

const language = window.localStorage.getItem(LANGUAGE_KEY)

function changeLanguage(languageKey: 'en' | 'es' | string): void {
	localStorage.setItem(LANGUAGE_KEY, languageKey)
	window.location.reload()
}

function getServiceHasUnits({
	type,
}: {
	type: string | number | undefined
}): boolean {
	switch (Number(type)) {
		case SERVICE_TYPE_ENUM.RodentsControl:
		case SERVICE_TYPE_ENUM.RodentsMonitoring:
		case SERVICE_TYPE_ENUM.BirdsMonitoring:
		case SERVICE_TYPE_ENUM.WildLifeControl:
		case SERVICE_TYPE_ENUM.WildLifeMonitoring:
		case SERVICE_TYPE_ENUM.InsectsMonitoring:
		case SERVICE_TYPE_ENUM.UVATubesChange:
			return true
		case SERVICE_TYPE_ENUM.InsectsControl:
		case SERVICE_TYPE_ENUM.BirdsControl:
		case SERVICE_TYPE_ENUM.UltrasonicFrequencyChange:
		case SERVICE_TYPE_ENUM.Disinfection:
		default:
			return false
	}
}

function getServiceHasApplication({
	type,
}: {
	type: string | number | undefined
}): boolean {
	switch (Number(type)) {
		case SERVICE_TYPE_ENUM.InsectsControl:
		case SERVICE_TYPE_ENUM.Disinfection:
		case SERVICE_TYPE_ENUM.Transports:
			return true
		case SERVICE_TYPE_ENUM.BirdsControl:
		case SERVICE_TYPE_ENUM.UltrasonicFrequencyChange:
		case SERVICE_TYPE_ENUM.RodentsControl:
		case SERVICE_TYPE_ENUM.RodentsMonitoring:
		case SERVICE_TYPE_ENUM.BirdsMonitoring:
		case SERVICE_TYPE_ENUM.WildLifeControl:
		case SERVICE_TYPE_ENUM.WildLifeMonitoring:
		case SERVICE_TYPE_ENUM.InsectsMonitoring:
		case SERVICE_TYPE_ENUM.UVATubesChange:
		default:
			return false
	}
}

function getUnitHasInfestation({ type }: { type: string }): boolean {
	if (type?.charAt(0) === '0') return true
	return false
}

function getUnitValidStatus({ type }: { type: string }): string[] {
	if (type?.[0] === '0')
		return SPECIFIC_UNIT_STATUSES.filter((value) => {
			switch (value) {
				case '03':
				case '10':
					return false
				default:
					return true
			}
		})
	if (type?.[0] === '4')
		return SPECIFIC_UNIT_STATUSES.filter((value) => {
			switch (value) {
				case '02':
				case '12':
				case 'A1':
					return false
				default:
					return true
			}
		})
	return SPECIFIC_UNIT_STATUSES.filter((value) => {
		switch (value) {
			case '03':
			case '10':
			case '02':
			case '12':
			case 'A1':
				return false
			default:
				return true
		}
	})
}

const INVALID_AREA = -1
function getAreaType(serviceType: number | string | undefined): number {
	switch (Number(serviceType)) {
		case SERVICE_TYPE_ENUM.RodentsControl:
		case SERVICE_TYPE_ENUM.RodentsMonitoring:
			return AREA_TYPE_ENUM.Rodents

		case SERVICE_TYPE_ENUM.InsectsControl:
		case SERVICE_TYPE_ENUM.Disinfection:
			return AREA_TYPE_ENUM.Insects

		case SERVICE_TYPE_ENUM.BirdsControl:
		case SERVICE_TYPE_ENUM.BirdsMonitoring:
		case SERVICE_TYPE_ENUM.UltrasonicFrequencyChange:
			return AREA_TYPE_ENUM.Birds

		case SERVICE_TYPE_ENUM.WildLifeControl:
		case SERVICE_TYPE_ENUM.WildLifeMonitoring:
			return AREA_TYPE_ENUM.WildLife

		case SERVICE_TYPE_ENUM.InsectsMonitoring:
		case SERVICE_TYPE_ENUM.UVATubesChange:
			return AREA_TYPE_ENUM.UVA

		default:
			return INVALID_AREA
	}
}

function tryGetFileNameFromLocation(fileUrl: string) {
	if (!fileUrl) return undefined

	try {
		const url = new URL(fileUrl)

		return url.pathname.split('/').pop()
	} catch (e) {
		return undefined
	}
}

export async function downloadFile({
	url: fileUrl,
	fileName,
	useAuth = false,
}: {
	url: string
	fileName?: string
	useAuth?: boolean
}) {
	const response = await fetch(fileUrl, {
		method: 'GET',
		headers: useAuth
			? { Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}` }
			: undefined,
	})

	// 2XX status code check
	if (response.status.toString().charAt(0) !== '2') {
		throw new Error(`Error downloading file: ${response.statusText}`)
	}

	const responseBlob = await response.blob()

	// Create a link element, click it and remove it
	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(responseBlob)
	link.download = fileName || tryGetFileNameFromLocation(fileUrl) || 'file'
	link.click()
	link.remove()
}

export function filterNull({
	all,
	type = '',
	t,
}: {
	all?: any[]
	type?: string
	t?: any
}) {
	if (type) {
		return {
			nodes: all
				?.filter(({ user }) => user !== null)
				?.map(({ user: { _id, name, surname } }) => ({
					_id,
					name: `${t(type)}: ${name} ${surname}`,
				})),
		}
	}
	return {
		nodes: all
			?.filter(({ user }) => Boolean(user))
			?.map(({ user: { _id, name, surname } }) => ({
				_id,
				name: `${name} ${surname}`,
			})),
	}
}

export const isProduction = import.meta.env?.MODE === 'production'
export const isDevelopment = import.meta.env?.MODE === 'development'
export const isTesting = import.meta.env?.MODE === 'test'

export function useForceUpdate(): () => void {
	const [, setTick] = useState(0)

	const update = useCallback(() => {
		setTick((tick) => tick + 1)
	}, [])
	return update
}

export function unslugify(slug: string): string {
	const result = slug.replace(/-/g, ' ')
	return result.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}

export function slugify(str: string, spaceReplace = '-') {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, spaceReplace)
		.replace(/^-+|-+$/g, '')
}

export function isEmpty(array: unknown): boolean {
	if (!array) return true
	if (Array.isArray(array)) return !array.length
	if (typeof array === 'string') return !array.length
	if (typeof array === 'object') return !Object.keys(array).length
	return false
}

export const DATE_FORMAT = 'yyyy-MM-dd'
export const TIME_FORMAT = 'HH:MM:ss'

export interface FucesaJWTClaims {
	roles: string[]
	org: string
	statements: string[]
}

export interface JWTClaims {
	sub: string
	email: string
	name: string
	[JWT_NAMESPACE]: FucesaJWTClaims
}

export function capitalize(s: string): string {
	return s[0].toUpperCase() + s.slice(1).toLowerCase()
}

/**
 * Returns the size in MB for a given byte size
 * @param sizeInBytes number
 * @returns number
 */
export function getMb(sizeInBytes: number): string {
	// eslint-disable-next-line no-magic-numbers
	return (Number(sizeInBytes) / (1024 * 1024)).toFixed(2)
}

export function getGraphQLErrorMessage(
	error: ApolloError,
	defaultMessage: string,
) {
	let networkErrorMessage
	if (
		error.networkError &&
		typeof error.networkError === 'object' &&
		'result' in error.networkError &&
		typeof error.networkError.result === 'object' &&
		error.networkError.result.errors.length
	) {
		networkErrorMessage = error.networkError.result.errors
			.map((error: any) => error.message)
			.join(', ')
	}

	return networkErrorMessage || error.message || defaultMessage
}

export const EMPTY_ARRAY = Object.freeze([]) as []

export * as SERVER_ERRORS from '@/utils/serverErrors'

export { default as get } from './get'

export function getCleanPrice(priceString: string): number {
	return Number(priceString?.replace?.(/[^0-9.]/g, '') || 0)
}

export function saveCredentials({
	oAuth,
	credentials,
	organization,
}: {
	credentials: any
	oAuth?: string
	organization?: any
}) {
	localStorage.setItem(ACCESS_TOKEN_KEY, credentials.token)

	if (organization?.slug) {
		localStorage.setItem(ORG_KEY, organization.slug)
	}

	const selectedFacility =
		credentials?.user?.facilities?.nodes?.['0']?._id || null

	apolloClient.cache.writeQuery({
		data: { oAuth, selectedFacility },
		query: GET_LOCAL_FIELDS,
	})

	const data = {
		self: {
			__typename: 'User',
			_id: credentials.user._id,
			access: credentials.user.access,
			name: credentials.user.name,
			surname: credentials.user.surname,
			...credentials.user,
		},
	}

	apolloClient.cache.writeQuery({ query: GET_SELF, data })
}

export function partition(array: any[], isValid: (element: any) => boolean) {
	return array.reduce(
		([pass, fail], elem) =>
			isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]],
		[[], []],
	)
}

export const COUNTRY_CODE_REGEX =
	/(?:\+|00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/

export function combineGraphQLErrorMessages(
	graphQLErrors: undefined | GraphQLError[],
	defaultError?: string,
) {
	return graphQLErrors?.map((error) => error.message).join(', ') || defaultError
}

export {
	getServiceHasUnits,
	getUnitHasInfestation,
	getUnitValidStatus,
	getServiceHasApplication,
	extractIds,
	getAreaType,
	getOptions,
	sortClientsByName,
	hasGraphQLErrorCode,
	cleanEmail,
	resetSessionData,
	getOrganizationSlug,
	dataURItoFile,
	changeLanguage,
	language,
	LANGUAGE_KEY,
	useToggle,
}
