import type {
	ApolloCache,
	BaseMutationOptions,
	DefaultContext,
	OperationVariables,
} from '@apollo/client'
import { makeVar, useReactiveVar } from '@apollo/client'

import type { modules } from '@/lib/constants'
import type { CompanyRole, Service, TransactionType } from '@/lib/generated/gql'

import { getSafeServerDate } from './dates'

interface Location {
	pathname?: string
	search?: string
}

function getLoginCallback({ pathname, search = '' }: Location): string {
	const initialSearchParams = new URLSearchParams(search)
	const originalOrg = initialSearchParams.get('org')

	initialSearchParams.delete('org')

	const encodedSearchParams = encodeURIComponent(initialSearchParams.toString())
	const finalSearchParams = new URLSearchParams({
		returnUri: `${pathname}${encodedSearchParams}`,
	})

	if (originalOrg) finalSearchParams.append('org', originalOrg)

	const loginCallback =
		pathname !== '/' &&
		pathname !== '/login' &&
		(pathname || encodedSearchParams)
			? `/login?${finalSearchParams}`
			: '/login'

	return loginCallback
}

function parseLoginCallbackPath({ search }: Location) {
	if (!search) return ''

	const encodedReturnURI = new URLSearchParams(search).get('returnUri') || ''
	const decodedReturnURI = decodeURIComponent(encodedReturnURI)
	return decodedReturnURI
}

function hsl2rgb(hue: number, saturation: number, luminance: number): number[] {
	// eslint-disable-next-line no-magic-numbers
	const a = saturation * Math.min(luminance, 1 - luminance)
	// eslint-disable-next-line no-magic-numbers
	const f = (n: number, k = (n + hue / 30) % 12): number =>
		// eslint-disable-next-line no-magic-numbers
		luminance - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
	// eslint-disable-next-line no-magic-numbers
	return [f(0), f(8), f(4)]
}

function rgb2hex([red, green, blue]: number[]): string {
	return (
		'#' +
		[red, green, blue]
			.map(($0) =>
				// eslint-disable-next-line no-magic-numbers
				Math.round($0 * 255)
					// eslint-disable-next-line no-magic-numbers
					.toString(16)
					// eslint-disable-next-line no-magic-numbers
					.padStart(2, '0'),
			)
			.join('')
	)
}

function getStatusColor({ general }: { general: string }): string {
	if (general) {
		switch (general) {
			case '2':
				return 'var(--human-color--yellow)'
			case 'D':
				return 'var(--human-color--orange)'
			case 'E':
				return 'var(--human-color--red)'
			case 'F':
				return 'var(--human-color--black)'
			default:
				return 'var(--human-color--blue)'
		}
	}

	return ''
}

function getInsectColor({
	code,
	index,
}: {
	code?: string
	index?: number
} = {}): string {
	let color = ''
	if (typeof index === 'number') {
		color = [
			'#FF0000',
			'#FFFF00',
			'#00EAFF',
			'#AA00FF',
			'#FF7F00',
			'#BFFF00',
			'#0095FF',
			'#FF00AA',
			'#FFD400',
			'#6AFF00',
			'#0040FF',
			'#EDB9B9',
			'#B9D7ED',
			'#E7E9B9',
			'#DCB9ED',
			'#B9EDE0',
			'#8F2323',
			'#23628F',
			'#8F6A23',
			'#6B238F',
			'#4F8F23',
			'#000000',
			'#737373',
			'#CCCCCC',
		][index]
	}

	if (color) return color
	if (!code) throw new Error('Please provide a fallback for colors')

	// eslint-disable-next-line no-magic-numbers
	const codeAsInt = parseInt(code, 32) - 48

	if (codeAsInt === 0) return '#000000'
	// eslint-disable-next-line no-magic-numbers
	else if (codeAsInt === 47) return '#666666'

	// eslint-disable-next-line no-magic-numbers
	const hue = codeAsInt * 7.82
	// eslint-disable-next-line no-magic-numbers
	const saturation = codeAsInt % 2 ? 1 : 0.8
	// eslint-disable-next-line no-magic-numbers
	const light = codeAsInt % 4 ? 0.5 : 0.3
	return rgb2hex(hsl2rgb(hue, saturation, light))
}

function groupServicesByYearAndMonth(services: Partial<Service>[] = []) {
	const years: Record<number, Record<number, Partial<Service>[]>> = {}

	services.forEach(({ _id, date: stringDate, ...other }) => {
		const date = getSafeServerDate(stringDate)
		const year = date.getFullYear()
		const month = date.getMonth() + 1

		if (!years[year]) {
			years[year] = { [month]: [{ _id, date, ...other }] }
		} else {
			if (!years[year][month]) {
				years[year][month] = [{ _id, date, ...other }]
			} else {
				years[year][month].push({ _id, date, ...other })
			}
		}
	})

	const yearsList = Object.entries(years).map(
		([year, objectMonths]: [string, unknown]) => ({
			year,
			months: Object.entries(objectMonths as Record<string, Service[]>).map(
				([month, monthServices]) => ({
					month,
					services: monthServices,
				}),
			),
		}),
	)

	return yearsList
}

function isValidTimeZone(values: {
	timeZone: string
}): Record<string, string | null> {
	const errors: Record<string, string | null> = {}
	if (values.timeZone) {
		const timeZone = values.timeZone.replace(/\s/g, '_')
		try {
			Intl.DateTimeFormat(undefined, { timeZone })
		} catch (error) {
			errors.timeZone = 'error.invalid.timeZone'
		}
	}
	return errors
}

export function useModals() {
	return useReactiveVar<Modal[]>(modalsVar)
}

export type ModalProps = {
	/**
	 * Don't call showModal inside here, add a timeout if needed
	 */
	onCompleted?: (
		data: any,
		clientOptions: BaseMutationOptions<
			any,
			OperationVariables,
			DefaultContext,
			ApolloCache<any>
		>,
	) => void
}

export type Modal =
	| [
			modules.share,
			ModalProps & {
				companyId: any
				resourceId?: any
				resourceFormats?: string[]
				type: any
				hasLink: boolean
				link?: any
			},
	  ]
	| [
			modules.invoices,
			ModalProps & {
				type: 'INVOICE' | 'SALE_NOTE'
				isUpdate?: boolean
				invoiceId?: string
			},
	  ]
	| [
			modules.employees,
			ModalProps & {
				/**
				 * Employee ID to edit
				 */
				employee: string
				email?: string
			},
	  ]
	| [modules.services, ModalProps]
	| [modules.clients, ModalProps & { type: CompanyRole }]
	| [modules.contacts, ModalProps & { companyId?: any }]
	| [modules.facilities, ModalProps & { companyId: any }]
	| [modules.products, ModalProps]
	| [modules.employee, ModalProps]
	| [modules.payments, ModalProps & { type: 'PAYMENT' }]
	| [modules.links, ModalProps & { invoiceId: any }]
	| [
			modules.transactions,
			ModalProps & { type?: TransactionType; transactionId?: any },
	  ]

export const modalsVar = makeVar<Modal[]>([] as any)

export function showModal(modal: Modal) {
	return modalsVar([...modalsVar(), modal])
}

export function popModal() {
	return modalsVar([...modalsVar()].slice(0, -1))
}

export function sortOptions(a: any, b: any) {
	const first = a.label
	const second = b.label

	if (first < second) {
		return -1
	}
	if (first > second) {
		return 1
	}
	return 0
}

export {
	getLoginCallback,
	parseLoginCallbackPath,
	rgb2hex,
	hsl2rgb,
	getInsectColor,
	groupServicesByYearAndMonth,
	getStatusColor,
	isValidTimeZone,
}
